<template>
    <v-stepper v-model="Stepper">
        <v-stepper-header>
            <v-stepper-step
                :complete="Stepper > 1"
                :editable="Stepper > 1"
                step="1">
                    Información cliente
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="Stepper > 2"
                editable
                step="2">
                    Dimensiones
            </v-stepper-step>

            <v-stepper-step
                :complete="Stepper > 3"
                editable
                step="3">
                    Mapas
            </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-card class="mb-5">
                    <v-card-title>
                        <span class="headline">Información</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Nombre cliente"
                                            v-model="infoTownhall.name"
                                            :error-messages="nameErrors"
                                            clearable
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Correo electrónico"
                                            v-model="infoTownhall.email"
                                            :error-messages="emailErrors"
                                            required
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Url cliente"
                                            v-model="infoTownhall.url"
                                            clearable
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Teléfono"
                                            v-model="infoTownhall.phone"
                                            :error-messages="phoneErrors"
                                            clearable
                                            type="number"
                                            required>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Facebook"
                                            v-model="infoTownhall.urlFacebook"
                                            clearable
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Twitter"
                                            v-model="infoTownhall.urlTwitter"
                                            clearable
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Instagram"
                                            v-model="infoTownhall.urlInstagram"
                                            clearable
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Youtube"
                                            v-model="infoTownhall.urlYoutube"
                                            clearable
                                            required>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-file-input
                                            accept="image/*"
                                            label="Logo"
                                            @change="onPrincipalImageChange"
                                            v-model="infoTownhall.logo"
                                            clearable
                                        ></v-file-input>
                                    </v-col>
                                    <v-col>
                                        <v-file-input
                                            accept="image/*"
                                            label="Banner"
                                            @change="onBannerImage"
                                            v-model="infoTownhall.banner"
                                        ></v-file-input>
                                    </v-col>
                                     <v-col>
                                        <v-file-input
                                            accept="image/*"
                                            label="Imagen Footer"
                                            @change="onFooterImage"
                                            v-model="infoTownhall.footerImage"
                                        ></v-file-input>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        Color primario
                                        <v-color-picker
                                            v-model="infoTownhall.primary_color"
                                            mode="hexa"
                                        ></v-color-picker>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Título footer"
                                            v-model="infoTownhall.footer_title"
                                            clearable
                                            required>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-textarea
                                            v-model="infoTownhall.what"
                                            :error-messages="whatErrors"
                                            clearable
                                            outlined
                                            clear-icon="mdi-close-circle"
                                            label="Texto: Qué es el observatorio">
                                        </v-textarea>
                                    </v-col>
                                    <v-col>
                                        <v-textarea  id="how"
                                            v-model="infoTownhall.how"
                                            :error-messages="howErrors"
                                            clearable
                                            outlined
                                            clear-icon="mdi-close-circle"
                                            label="Texto: ¿A quién va dirigido?">
                                        </v-textarea>
                                    </v-col>
                                    <v-col>
                                        <v-textarea  id="why"
                                            v-model="infoTownhall.why"
                                            :error-messages="whyErrors"
                                            clearable
                                            outlined
                                            clear-icon="mdi-close-circle"
                                            label="Texto: Visión">
                                        </v-textarea>
                                    </v-col>
                                    <v-col>
                                        <v-textarea  id="for"
                                            v-model="infoTownhall.for"
                                            :error-messages="whyErrors"
                                            clearable
                                            outlined
                                            clear-icon="mdi-close-circle"
                                            label="Texto: ¿Para qué sirve?">
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-btn
                    color="primary"
                    @click="saveInfoTownhall">
                        Continuar
                </v-btn>
                <v-btn text>
                    Cancelar
                </v-btn>
            </v-stepper-content>
        
            <v-stepper-content step="2">
                <v-dialog
                    v-model="dialogDimension"
                    persistent
                    max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="mb-5"
                            @click="newDimension">
                                Nueva dimensión
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">Nueva dimensión</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Nombre dimensión"
                                            v-model="infoDimension.name"
                                            :error-messages="nameDimensionErrors"
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="infoDimension.description"
                                            :error-messages="descriptionDimensionErrors"
                                            label="Descripción dimensión"
                                            required>   
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <img :src="url" style="width: 100%; height: 300px">
                                        <v-file-input
                                            accept="image/*"
                                            v-model="infoDimension.img"
                                            :error-messages="imgDimensionErrors"
                                            prepend-icon="mdi-camera"
                                            label="Imagen fondo dimensión">
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-color-picker
                                            v-model="infoDimension.color"
                                            mode="hexa"
                                        ></v-color-picker>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="red darken-1"
                                text
                                @click="dialogDimension = false">
                                    Cerrar
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="saveDimension()">
                                Guardar
                            </v-btn>
                            
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-row class="mb-5">
                    <v-col lg="3" xl="3" md="4" sm="4" v-for="item in dimensions" v-bind:key="item.id" >
                        <v-card>
                            <v-container class="dimension_container" :style="{'background-image': `url(${item.img})`, 'background-size': 'cover', 'background-position': 'center'}">
                                <div class="dimension_container__title" :style="{'background-color':item.color}">
                                    <p>
                                        {{item.name}}
                                    </p>
                                </div>
                            </v-container>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click="dialogDimension = true">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar dimensión</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click="getSegments(1)">
                                            <v-icon>mdi-arrange-send-to-back</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Segmentos</span>
                                </v-tooltip>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <!-- AQUIIIII-->
            <v-btn
                    color="primary"
                    @click="saveInfoTownhall">
                        Continuar
                </v-btn>
                <v-btn text>
                    Cancelar
                </v-btn>
            <v-dialog
                v-model="dialogSegment"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                scrollable>
      
                <v-card tile>
                    <v-toolbar
                        flat
                        dark
                        color="primary">
                        <v-btn
                            icon
                            dark
                            @click="dialogSegment = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Segmentos</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                                dark
                                text
                                @click="dialogSegment = false">
                                <v-icon left>
                                    mdi-card-plus
                                </v-icon>
                                Nuevo segmento
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <!-- INFORMACIÓN DE CARDS -->
                        <v-row>
                            <v-col cols="6">
                                <v-card class="mt-3">
                                    <v-toolbar
                                        flat
                                        color="primary"
                                        dark>
                                        <v-toolbar-title>Planos y mapas</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-toolbar-items>
                                            <v-btn icon>
                                            <v-icon>mdi-content-save</v-icon>
                                        </v-btn>
                                        <v-btn icon>
                                            <v-icon>mdi-content-save-edit</v-icon>
                                        </v-btn>
                                        </v-toolbar-items>
                                    </v-toolbar>
                                    <v-tabs>
                                        <v-tab>
                                            <v-icon left>
                                            mdi-card-text
                                            </v-icon>
                                            Información
                                        </v-tab>
                                        <v-tab>
                                            <v-icon left>
                                            mdi-ballot
                                            </v-icon>
                                            Lista
                                        </v-tab>
                                        <v-tab>
                                            <v-icon left>
                                            mdi-chart-arc
                                            </v-icon>
                                            Gráficos
                                        </v-tab>
                                            <v-tab-item>
                                                <v-card flat>
                                                    <v-card-text>
                                                        
                                                        <v-form>
                                                            <v-container>
                                                            <v-row>
                                                                <v-col
                                                                    cols="6">
                                                                    <v-text-field
                                                                        label="Nombre segmento"
                                                                        required></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="6">
                                                                    <v-file-input
                                                                        accept="image/*"
                                                                        label="Ícono"
                                                                        prepend-icon="mdi-camera"
                                                                    ></v-file-input>
                                                                </v-col>
                                                            </v-row>
                                                            </v-container>
                                                        </v-form>
                                                    </v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-card flat>
                                                    <v-card-text>
                                                        <v-form>
                                                            <v-container>
                                                            <v-row>
                                                                <v-col cols="12">
                                                                <v-text-field
                                                                    append-outer-icon="mdi-send"
                                                                    filled
                                                                    clear-icon="mdi-close-circle"
                                                                    clearable
                                                                    label="Item"
                                                                    type="text"
                                                                ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            </v-container>
                                                        </v-form>
                                                        <v-chip-group
                                                            active-class="primary--text"
                                                            column
                                                        >
                                                            <v-chip close close-icon="mdi-delete">
                                                                Item
                                                            </v-chip>
                                                        </v-chip-group>
                                                    </v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-card flat>
                                                    <v-card-text>
                                                        Gráficos
                                                    </v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                    </v-tabs>
                                </v-card>
                            </v-col>
                        </v-row>
                        
                        <!-- INFORMACIÓN DE CARDS -->
                    </v-card-text>
                </v-card>
            </v-dialog>
            </v-stepper-content>



            <v-stepper-content step="3">
                <v-card class="mb-5">
                    <v-card-title>
                        <span class="headline">Información</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Url mapa del sitio"
                                            v-model="infoTownhall.url_site_map"
                                            :error-messages="nameErrors"
                                            clearable
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Url mapa Argis"
                                            v-model="infoTownhall.url_argis_map"
                                            :error-messages="emailErrors"
                                            required
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>

                <v-btn @click="saveTownhall()"
            color="primary">
                Guardar   
            </v-btn>
            <v-btn text>
                Cancelar
            </v-btn>

            </v-stepper-content>









        </v-stepper-items>
    </v-stepper>
</template>
<script>
import { required, email, numeric } from 'vuelidate/lib/validators'
  export default {
    data () {
      return {
        current_logo_url:'',
        current_banner_url:'',
        current_footer_url:'',
        Stepper: 1,
        dialogDimension: false,
        dialogSegment: false,
        titleDimension: 'Nueva dimensión',
        laImg:'',
        dimensions:[],
        infoTownhall: {
            name: '',
            email: '',
            url: '',
            phone: '',
            urlFacebook: '',
            urlTwitter: '',
            urlInstagram: '',
            urlYoutube: '',
            logo: null,
            banner: null,
            footerImage: null,
            what: '',
            how: '',
            why: '',
            for: '',
            primary_color: null,
            footer_title: null,
            dimensions: [],
            client_id: this.getUser().user_id,
            url_site_map:'',
            url_argis_map:''
        },
        infoDimension: {
            name: '',
            description: '',
            img: null,
            color: null
        },
      }
    },
    validations: {
        infoTownhall: {
            name: {required},
            email: {required, email},
            phone: {required},
            what: {required},
            how: {required},
            why: {required},
        },
        infoDimension: {
            name: {required},
            description: {required},
            img: {required}
        }
    },
    computed: {
        nameErrors(){
            const errors = []
            if (!this.$v.infoTownhall.name.$dirty) return errors
            !this.$v.infoTownhall.name.required && errors.push('Este campo es requerido.')
            return errors
        },
        emailErrors(){
            const errors = []
            if (!this.$v.infoTownhall.email.$dirty) return errors
            !this.$v.infoTownhall.email.required && errors.push('Este campo es requerido.')
            !this.$v.infoTownhall.email.email && errors.push('El correo electrónico debe ser válido.')
            return errors
        },
        phoneErrors(){
            const errors = []
            if (!this.$v.infoTownhall.phone.$dirty) return errors
            !this.$v.infoTownhall.phone.required && errors.push('Este campo es requerido.')
            return errors
        },
        whatErrors(){
            const errors = []
            if (!this.$v.infoTownhall.what.$dirty) return errors
            !this.$v.infoTownhall.what.required && errors.push('Este campo es requerido.')
            return errors
        },
        howErrors(){
            const errors = []
            if (!this.$v.infoTownhall.how.$dirty) return errors
            !this.$v.infoTownhall.how.required && errors.push('Este campo es requerido.')
            return errors
        },
        whyErrors(){
            const errors = []
            if (!this.$v.infoTownhall.why.$dirty) return errors
            !this.$v.infoTownhall.why.required && errors.push('Este campo es requerido.')
            return errors
        },
        nameDimensionErrors(){
            const errors = []
            if (!this.$v.infoDimension.name.$dirty) return errors
            !this.$v.infoDimension.name.required && errors.push('Este campo es requerido.')
            return errors
        },
        descriptionDimensionErrors(){
            const errors = []
            if (!this.$v.infoDimension.description.$dirty) return errors
            !this.$v.infoDimension.description.required && errors.push('Este campo es requerido.')
            return errors
        },
        imgDimensionErrors(){
            const errors = []
            if (!this.$v.infoDimension.img.$dirty) return errors
            !this.$v.infoDimension.img.required && errors.push('Este campo es requerido.')
            return errors
        },
        url(){
            if (!this.infoDimension.img) return;
            return URL.createObjectURL(this.infoDimension.img);
        },
        logo(){
            if (!this.infoDimension.logo) return;
            return this.current_logo_url
        },
        banner(){
            if (!this.infoDimension.banner) return;
            return this.current_banner_url
        },
        footerImage(){
            if (!this.infoDimension.footerImage) return;
            return this.current_footer_url
        }
    },
    methods: {
        newDimension(){
            this.infoDimension = {
                name: '',
                description: '',
                img: null,
                color: null
            };
            this.dialogDimension = true;
        },
        saveInfoTownhall(){
            this.$v.infoTownhall.$touch();
            if (!this.$v.infoTownhall.$invalid) {
                this.Stepper = 2;
            }else{
                console.log('Formulario inválido');
            }
        },
        getSegments(Dimension){
    
            this.dialogSegment = true;

        },
        onPrincipalImageChange (e) {
        var files = e
        console.log(e)
        this.current_logo_url = URL.createObjectURL(files)
        console.log(this.current_principal_url)
        return this.createPrincipalImage(files)
      },
      createPrincipalImage (file) {
        var reader = new FileReader()
        var vm = this
        reader.readAsDataURL(file);
        reader.onload = function () {
          vm.infoTownhall.logo = reader.result
        }
      },
      onBannerImage(e){
            var files = e
            this.current_banner_url = URL.createObjectURL(files)
            return this.createBannerImage(files)
        },
        createBannerImage (file) {
        var reader = new FileReader()
        var vm = this
        reader.readAsDataURL(file);
        reader.onload = function () {
          vm.infoTownhall.banner = reader.result
        }
      },
    onFooterImage(e){
        var files = e
        this.current_footer_url = URL.createObjectURL(files)
        return this.createFooterImage(files)  
    },
    createFooterImage (file) {
        var reader = new FileReader()
        var vm = this
        reader.readAsDataURL(file);
        reader.onload = function () {
          vm.infoTownhall.footerImage = reader.result
          console.log('footer',vm.infoTownhall.footerImage)
        }
    },
        async saveTownhall(){
            console.log('entre a guardar',this.getCrypto())
            try{
                const townhalls = await this.$http.post('townhalls', { data: {
                attributes: {
                    email: this.infoTownhall.email,
                    facebook_url: this.infoTownhall.urlFacebook,
                    how: this.infoTownhall.how,
                    instagram_url: this.infoTownhall.urlInstagram,
                    landing_img:this.infoTownhall.banner,
                    logo_img:this.infoTownhall.logo,
                    logo_townhall:this.infoTownhall.footerImage,
                    name_townhall: this.infoTownhall.name,
                    phone: this.infoTownhall.phone,
                    primary_color: this.infoTownhall.primary_color.hexa,
                    footer_title: this.infoTownhall.footer_title,
                    twitter_url: this.infoTownhall.urlTwitter,
                    youtube_url: this.infoTownhall.urlYoutube,
                    what: this.infoTownhall.what,
                    why: this.infoTownhall.why,
                    for: this.infoTownhall.for,
                    url: this.infoTownhall.url,
                    client_id: this.infoTownhall.client_id,
                    url_site_map: this.infoTownhall.url_site_map,
                    url_argis_map: this.infoTownhall.url_argis_map,
            }}},{headers:{"Authorization": 'Bearer '+this.getCrypto()}}).then(response => {
                // this.uploadDimensionInApi(response.data.id)
                this.$router.push({ name: 'Clients' });
                
            }).catch(error => {
                console.log('Error', error)
            })
         
            // }}},{headers:{"Authorization": 'Bearer '+this.getCrypto()}}).then(response => {
          
            }catch(error){
            }


        },
        async uploadDimensionInApi(id){
            for(var i = 0; i< Object.keys(this.dimensions).length ; i++){
                const dimension = await this.$http.post('dimensions', { data: {
                attributes: {
                    townhall_id:id,
                    name_dimension:this.dimensions[i].name,
                    img_dimension:this.dimensions[i].file,
                    color_dimension:this.dimensions[i].color,
                    text_dimension:this.dimensions[i].description
            }}},{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
            // }}},{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
            }
            
        },
        saveDimension(){
            console.log('entre aca')
            this.$v.infoDimension.$touch();
            if (!this.$v.infoDimension.$invalid) {
                this.dimensions.push({
                    name: this.infoDimension.name,
                    description: this.infoDimension.description,
                    img: URL.createObjectURL(this.infoDimension.img),
                    file:this.infoDimension.img,
                    color: this.infoDimension.color.hexa,
                });
                this.dialogDimension = false;

            }else{
                console.log('Formulario inválido');
            }
        }
    },
    beforeMount(){
    }
  }
</script>